<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true" size="small">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入关键词" size="small" clearable>
          <el-button slot="append" class="iv-search" size="small" type="primary" @click="handleSearch">搜索</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini">
      <el-table-column
          prop="order_sn"
          width="150"
          label="订单号"
          align="center"
          fixed>
      </el-table-column>
      <el-table-column
          prop="member_name"
          min-width="240"
          label="推客信息">
        <template slot-scope="scope">
            <div class="pd-10">{{scope.row.member_name}}（{{scope.row.member_mobile}}）</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="member_user_id"
          min-width="240"
          label="推客账号">
      </el-table-column>
      <el-table-column
          prop="level"
          width="150"
          label="推客等级"
          align="center">
      </el-table-column>
      <el-table-column
          prop="commission"
          width="150"
          label="结算金额"
          align="center">
      </el-table-column>
      <el-table-column
          prop="settle_time"
          min-width="150"
          label="结算时间"
          align="center">
        <template slot-scope="scope">
          <span>{{scope.row.settle_time || '-'}}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    getList() {
      var _this = this
      _this.showLoading()
      var param = {
        page: _this.search.current_page,
        page_size: _this.search.limit,
        keywords: _this.search.keywords
      }
      this.$api.distribution.guiderSettleIndex(param, function (res) {
        _this.hideLoading()
        if (res.errcode == 0) {
          _this.tableData = res.data.data
          _this.search.current_page = res.data.current_page
          _this.search.total = res.data.total
        } else {
          _this.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
